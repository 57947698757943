<template>
    <v-main :class="checkDarkTheme() ? 'dark-theme' : 'light-theme'">
        <v-container class="fill-height" fluid>
            <v-row>
                <v-col cols="12" md="4" offset-md="4">
                    <!--                     <div class="outer-logo">
                        <img width="200" src="@/assets/logo01.svg" />
                    </div> -->
                    <div v-if="formStatus">
                        <div class="login-card" v-if="!status">
                            <div class="logo">
                                <h2>Molimo unesite novi password</h2>
                            </div>
                            <div class="fields">
                                <v-form ref="form" v-model="valid" @submit.prevent="reset">
                                    <v-text-field
                                        autocomplete="new-password"
                                        :rules="passwordRule"
                                        filled
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show1 ? 'text' : 'password'"
                                        label="Password"
                                        @click:append="show1 = !show1"
                                        v-model="password"
                                        dense
                                    ></v-text-field>

                                    <v-btn @click="reset" class="submit-button" color="primary" block depressed
                                        >Reset</v-btn
                                    >
                                </v-form>
                            </div>
                            <v-divider></v-divider>
                        </div>
                        <div class="login-card" v-if="status">
                            <div class="info-section">
                                <h2>Uspjesno ste promjenili password.</h2>
                                <br />
                                <p>Sada se mozete <router-link to="/login">logovati</router-link></p>
                            </div>
                        </div>
                    </div>
                    <div v-if="!formStatus">
                        <div class="login-card" v-if="!status">
                            <div class="logo">
                                <h2>Kod nije validan</h2>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-snackbar v-model="snackbarNotification.status" color="#446e9b">
            {{ snackbarNotification.text }}
            <v-btn color="white" text @click="$store.state.snackbarNotification.status = false">Close</v-btn>
        </v-snackbar>

        <v-dialog v-model="showError" persistent max-width="500">
            <v-card>
                <v-card-title>Problem na servisu </v-card-title>
                <v-card-text>{{ $store.state.error.text }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeError">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            valid: true,
            password: '',
            code: '',
            show1: false,
            passwordRule: [(value) => value.length >= 8 || 'Duzima passworda mora biti minimalno 8 karaktera']
        }
    },
    destroyed() {
        this.$store.commit('login/setResetSuccess', false)
    },
    computed: {
        showError() {
            return this.$store.state.errorDialog
        },
        snackbarNotification() {
            return this.$store.state.snackbarNotification
        },
        status() {
            return this.$store.state.login.resetSuccess
        },
        formStatus() {
            return this.$store.state.login.resetFormStatus
        }
    },
    created() {
        this.code = this.$route.params.hash
        this.checkCode()
    },
    methods: {
        reset() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch('login/reset', {
                    new_password: this.password,
                    code: this.code
                })
                return true
            }
        },
        closeError() {
            this.$store.commit('closeError')
        },
        checkCode() {
            this.$store.dispatch('login/checkCode', this.code)
        }
    }
}
</script>

<style scoped>
.fields {
    margin-top: 25px;
    margin-bottom: 25px;
}

.fields span.terms {
    text-align: center;
    display: block;
    margin-top: 15px;
    color: #4c4c4c;
    font-size: 12px;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo h2 {
    color: #4c4c4c;
    text-align: left;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 18px;
}

.logo p {
    font-size: 14px;
    margin-bottom: 0px;
}

.login-card {
    padding: 50px;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.info-section {
    margin-top: 30px;
    text-align: center;
    color: #4c4c4c;
}

.new-account {
    margin-top: 15px;
    text-align: center;
    color: #4c4c4c;
}

.v-btn.submit-button {
    padding: 15px 0px;
    height: auto;
}

.outer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

@media screen and (max-width: 475px) {
    .login-card {
        padding: 0px;
        box-shadow: none;
    }
}
</style>
