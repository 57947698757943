<template>
    <v-main :class="checkDarkTheme() ? 'dark-theme' : 'light-theme'">
        <v-container class="fill-height" fluid>
            <v-row>
                <v-col cols="12" md="8" offset-md="2">
                    <!--                     <div class="outer-logo">
                        <img width="200" src="@/assets/logo01.svg" />
                    </div> -->
                    <div class="login-card">
                        <div class="logo">
                            <h2>2 Step Verifikacija</h2>
                        </div>
                        <div class="stepper">
                            <v-stepper v-model="step">
                                <v-stepper-header>
                                    <v-stepper-step :complete="step > 1" step="1">Dobro došli</v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :complete="step > 2" step="2">Recovery Ključ</v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step step="3">Google Authenticator Setup</v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step step="4">Login</v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <p>
                                                        Naš servis koristi Google 2-step verifikaciju za zaštitu. Vaš
                                                        identitet će biti potvrđen koristeći vaš mobilni uređaj.
                                                    </p>

                                                    <p>
                                                        U toku 2-step verifikacije dobit čete Recovery ključ koji
                                                        kasnije možete koristiti da obnovite 2-step verifikaciju.
                                                    </p>

                                                    <p>
                                                        Recovery ključ trebate iskoristiti samo u slučaju da izgubite
                                                        mobilni uređaj ili slićno.
                                                    </p>

                                                    <p>
                                                        Da bi koristili aplikaciju potrebno je da je downloadujete sa
                                                        Google Play Storea ili Apple App Storea pod nazivom "Google
                                                        Authenticator".
                                                    </p>

                                                    <v-row>
                                                        <v-col cols="12" md="6">
                                                            <p>Google authenticator možete skinuti sa:</p>
                                                        </v-col>
                                                        <v-col cols="12" md="2">
                                                            <v-icon>mdi-cellphone-iphone</v-icon>
                                                            <a
                                                                href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                                                                target="_blank"
                                                                >iOS</a
                                                            >
                                                        </v-col>
                                                        <v-col cols="12" md="2">
                                                            <v-icon>mdi-cellphone-android</v-icon>
                                                            <a
                                                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                                                                target="_blank"
                                                                >Android</a
                                                            >
                                                        </v-col>
                                                    </v-row>

                                                    <br />
                                                    <v-btn color="primary" @click="redirectLogin()"> Nastavi </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-stepper-content>

                                    <v-stepper-content step="2">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <p>Vas recovery kljuc je:</p>

                                                    <div class="code">
                                                        <h4>{{ recovery }}</h4>
                                                    </div>

                                                    <p>
                                                        Pomoću ovog koda moći čete resetovati 2-step verifikaciju.
                                                        Isprintajte ili zapišite
                                                        <strong>Recovery ključ</strong>.
                                                    </p>

                                                    <v-btn color="primary" @click="checkRecovery"> nastavite </v-btn>

                                                    <v-btn @click="updateStep(1)" text>Nazad</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-stepper-content>

                                    <v-stepper-content step="3">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <p>
                                                        Pokrenite Google Authenticator na vašem mobilnom uređaju i
                                                        pratite naredna 3 koraka:
                                                    </p>
                                                    <ul>
                                                        <ol>
                                                            1. Pritisnite Add tipku u Google Authenticatoru
                                                        </ol>
                                                        <ol>
                                                            2. Izaberite opciju "Scan a barcode"
                                                            <br />
                                                        </ol>
                                                        <ol>
                                                            3. Skenirajte QR code: *
                                                        </ol>
                                                    </ul>

                                                    <div
                                                        id="qrauth"
                                                        :style="{
                                                            backgroundImage: `url('data:image/png;base64,${qrcode}')`
                                                        }"
                                                    ></div>

                                                    <p>
                                                        Unesite 6-o cifreni kod sa vašeg mobilnog uređaja koji ste
                                                        upravo dodali.
                                                    </p>

                                                    <v-form
                                                        ref="form3"
                                                        v-model="valid"
                                                        @submit.prevent="confirmGoogleAuth"
                                                    >
                                                        <v-text-field
                                                            label="Authenticator Code"
                                                            type="number"
                                                            v-model="gauth"
                                                            :rules="onlyNumbers"
                                                            :disabled="gauthError"
                                                        ></v-text-field>

                                                        <div
                                                            type="error"
                                                            class="error-message"
                                                            v-if="twoFACodeBadAttemptsLimit"
                                                        >
                                                            Invalidan Google Authenticator Kod.
                                                            <span class="notice">
                                                                Pokušajte odraditi "refresh" stranice te ponoviti
                                                                proces.
                                                            </span>
                                                        </div>

                                                        <div type="error" class="error-message" v-if="gauthError">
                                                            Dostigli ste maksimalan broj pokušaja za unošenje 2-Step
                                                            Verifikacionog koda. <br />
                                                            Molimo ponovo se logujte.
                                                            <span class="notice">
                                                                Pokušajte odraditi "refresh" stranice te ponoviti
                                                                proces.
                                                            </span>
                                                        </div>

                                                        <v-btn
                                                            :disabled="!valid"
                                                            color="primary"
                                                            type="submit"
                                                            v-if="!gauthError"
                                                        >
                                                            Loguj se
                                                        </v-btn>
                                                        <v-btn color="primary" @click="redirectLogin" v-if="gauthError">
                                                            Loguj se
                                                        </v-btn>
                                                    </v-form>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-dialog v-model="showError" persistent max-width="500">
                <v-card>
                    <v-card-title>Problem na servisu </v-card-title>
                    <v-card-text>{{ $store.state.error.text }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="closeError">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            e1: 1,
            onlyNumbers: [
                (x) => !!x || 'Potrebno polje.',
                (x) => (x && x.length == 6) || 'Morate unjeti tacno 6 cifara'
            ],
            recoveryCorrect: [(x) => x == this.recovery || 'Recovery kljuc pogresan.'],
            valid: true,
            recoveryValid: true,
            gauth: '',
            recoveryInput: ''
        }
    },
    created() {
        if (this.recovery == '') return this.$router.push({ name: 'login' })
    },
    computed: {
        recovery() {
            return this.$store.state.login.recKey
        },
        qrcode() {
            return this.$store.state.login.qrcode
        },
        step() {
            return this.$store.state.login.step
        },
        gauthError() {
            return this.$store.state.login.verificationError
        },
        showError() {
            return this.$store.state.errorDialog
        },
        twoFACodeBadAttemptsLimit() {
            return this.$store.state.login.twoFACodeBadAttemptsLimit
        }
    },
    destroyed() {
        this.clearData()
    },
    methods: {
        redirectLogin() {
            this.$store.dispatch('login/redirectLogin')
        },
        updateStep(value) {
            this.$store.commit('login/setStep', value)
        },
        checkRecovery() {
            this.$store.dispatch('login/authconfirm', this.recovery)
            return true
        },
        confirmGoogleAuth() {
            if (this.$refs.form3.validate()) {
                // 2fa checkout
                this.$store.dispatch('login/doTwoFactorAuth', {
                    gauthcode: this.gauth,
                    confirm: true
                })

                return true
            }
        },
        closeError() {
            this.$store.commit('closeError')
        },
        clearData() {
            this.valid = true
            this.recoveryValid = true
            this.gauth = ''
            this.recoveryInput = ''
        }
    }
}
</script>

<style scoped>
.new-account {
    margin-top: 15px;
    text-align: center;
    color: #4c4c4c;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
}

.logo h2 {
    color: #4c4c4c;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
}

.login-card {
    padding: 50px;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.outer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.code {
    margin-bottom: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    padding: 10px;
}

#qrauth {
    width: 200px;
    height: 200px;
    margin: 0px auto 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.error-message {
    color: red;
    padding: 5px 0px;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
}

.notice {
    font-size: 12px;
    text-align: center;
    color: rgba(60, 66, 87, 1);
    display: block;
}

.note {
    font-style: italic;
}

.dark-theme .info-section {
    color: #fff;
}

.info-section {
    margin-top: 30px;
    text-align: center;
    color: #4c4c4c;
}

@media screen and (max-width: 475px) {
    .login-card {
        padding: 0px;
        box-shadow: none;
    }

    .v-application ol {
        padding-left: 5px;
    }
}
</style>
