<template>
    <v-main :class="checkDarkTheme() ? 'dark-theme' : 'light-theme'">
        <v-container class="fill-height" fluid>
            <v-row>
                <v-col cols="12" md="4" offset-md="4">
                    <!--                     <div class="outer-logo">
                        <img width="200" src="@/assets/logo01.svg" />
                    </div> -->
                    <div class="login-card">
                        <div class="logo">
                            <h2>Reset 2-Step Verifikacije</h2>
                            <p>Da bi ste resetovali 2-Step Verifikaciju, molimo unesite svoj email i recovery kljuc:</p>
                        </div>
                        <div class="fields">
                            <v-form ref="form" v-model="valid" @submit.prevent="checkFields">
                                <v-text-field
                                    label="Email"
                                    :rules="emailRules"
                                    v-model="recover.email"
                                    outlined
                                ></v-text-field>
                                <v-text-field
                                    label="Recover Key"
                                    :rules="recoveryRules"
                                    v-model="recover.key"
                                    outlined
                                ></v-text-field>

                                <div type="error" class="error-message" v-if="badrecovery">
                                    Invalidan recovery kljuc
                                </div>

                                <v-btn class="submit-button" @click="checkFields" color="primary" block depressed
                                    >Reset</v-btn
                                >
                            </v-form>
                        </div>
                        <v-divider></v-divider>
                        <div class="new-account">
                            <router-link to="/login">Loguj se</router-link>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            valid: true,
            recover: {
                email: '',
                key: ''
            },
            emailRules: [(v) => !!v || 'E-mail je potreban', (v) => /.+@.+/.test(v) || 'E-mail mora biti validan'],
            recoveryRules: [
                (v) => !!v || 'Recovery kljuc je potreban',
                (v) => (v && v.length >= 1) || 'Molimo unesite svoj recovery kljuc'
            ]
        }
    },
    destroyed() {
        this.$store.commit('login/setRecoveryStatus', false)
    },
    computed: {
        badrecovery() {
            return this.$store.state.login.recoveryStatus
        }
    },
    methods: {
        checkFields() {
            if (this.$refs.form.validate()) {
                // 2fa checkout
                this.$store.dispatch('login/recovery', {
                    recoveryKey: this.recover.key,
                    email: this.recover.email
                })

                return true
            }
        }
    }
}
</script>

<style scoped>
.fields {
    margin-top: 25px;
    margin-bottom: 25px;
}

.fields span.terms {
    text-align: center;
    display: block;
    margin-top: 15px;
    color: #4c4c4c;
    font-size: 12px;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo h2 {
    color: #4c4c4c;
    text-align: left;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 18px;
}

.logo p {
    font-size: 14px;
    margin-bottom: 0px;
}

.login-card {
    padding: 50px;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.info-section {
    margin-top: 30px;
    text-align: center;
    color: #4c4c4c;
}

.new-account {
    margin-top: 15px;
    text-align: center;
    color: #4c4c4c;
}

.v-btn.submit-button {
    padding: 15px 0px;
    height: auto;
}

.outer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.error-message {
    color: red;
    padding: 5px 0px;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
}

@media screen and (max-width: 475px) {
    .login-card {
        padding: 0px;
        box-shadow: none;
    }
}
</style>
