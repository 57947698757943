<template>
    <v-main :class="checkDarkTheme() ? 'dark-theme' : 'light-theme'">
        <v-container class="fill-height" fluid>
            <v-row>
                <v-col cols="12" md="8" offset-md="2">
                    <!--                     <div class="outer-logo">
                        <img width="200" src="@/assets/logo01.svg" />
                    </div> -->
                    <div class="register-card">
                        <div v-if="registrationStatus" transition="fade-transition">
                            <v-row>
                                <v-col cols="12" md="12" class="text-center">
                                    <div class="animation">
                                        <div class="success-animation">
                                            <svg
                                                class="checkmark"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 52 52"
                                            >
                                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path
                                                    class="checkmark__check"
                                                    fill="none"
                                                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <h3>Hvala vam na registracij!</h3>
                                    <br />
                                    <h4>Provjerite email za potvrdu accounta.</h4>
                                    <br />
                                    <p>Nazad na <router-link to="/login">Login</router-link> stranicu</p>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="registrationStatus !== true" transition="fade-transition">
                            <v-row>
                                <v-col cols="12" md="12" class="logo">
                                    <h2>Molimo unesite detalje:</h2>
                                </v-col>
                            </v-row>
                            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validateForm">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            autocomplete="user_email"
                                            filled
                                            label="Email"
                                            v-model="registration.email"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            autocomplete="new-password"
                                            :rules="[rules.counter]"
                                            filled
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            @click:append="show1 = !show1"
                                            :type="show1 ? 'text' : 'password'"
                                            label="Password"
                                            v-model="registration.password"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            autocomplete="new-password"
                                            :rules="[rules.passCheck]"
                                            filled
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            @click:append="show1 = !show1"
                                            :type="show1 ? 'text' : 'password'"
                                            label="Ponovi Password"
                                            v-model="registration.passwordRetype"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-checkbox
                                        v-model="checkbox"
                                        :error-messages="checkboxErrors"
                                        :rules="[rules.terms]"
                                        required
                                        label=""
                                        @change="$v.checkbox.$touch()"
                                        @blur="$v.checkbox.$touch()"
                                    >
                                        <template v-slot:label>
                                            <div>
                                                Da li se slažete sa
                                                <v-tooltip location="bottom">
                                                    <template v-slot:activator="{ props }">
                                                        <a
                                                            target="_blank"
                                                            href="https://balkanac.net/agb"
                                                            v-bind="props"
                                                            @click.stop
                                                        >
                                                            pravilima portala?
                                                        </a>
                                                    </template>
                                                </v-tooltip>
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                                <v-row class="button-row">
                                    <v-col cols="12" md="6">
                                        <vue-recaptcha
                                            @verify="onVerify"
                                            @expired="onExpired"
                                            :loadRecaptchaScript="true"
                                            size="normal"
                                            sitekey="6LeuJfEcAAAAAEm0HlbkErZu4UWF06X6IWNpgkv7"
                                        >
                                        </vue-recaptcha>
                                        <span class="captcha-error" v-if="showCaptchaError"
                                            >Prije nego nastavite uradite CAPTCHU.</span
                                        >
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-btn color="primary" @click="validateForm" block depressed
                                            >Kreiraj Račun
                                        </v-btn>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        Već imate račun? <router-link to="/login">Loguj se</router-link>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-dialog v-model="showError" persistent max-width="500">
                <v-card>
                    <v-card-title>Problem na servisu </v-card-title>
                    <v-card-text>{{ $store.state.error.text }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="closeError">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-main>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import countryISOMapping from '@/assets/js/countryISOMapping.js'
import country_phone_routes from '@/assets/js/countryCodes.js'

// const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export default {
    components: { VueRecaptcha },
    data() {
        return {
            valid: true,
            show1: false,
            captchaToken: null,
            showCaptchaError: false,
            registration: {
                company: '',
                first_name: '',
                last_name: '',
                password: '',
                passwordRetype: '',
                address: '',
                country: '',
                phone: '',
                email: '',
                messages: '0'
            },
            checkbox1: false,
            checkbox2: false,
            checkbox: false,
            rules: {
                required: (value) => !!value || 'Potrebno',
                counter: (value) => value.length >= 8 || 'Duzina passworda minimalno 8 karaktera',
                meta: (value) => /^$|^([0-9a-z\-~]+(\.[0-9a-z\-~]+)*)$/.test(value) || 'Not a valid meta format.',
                terms: (value) => !!value || 'Morate se sloziti da bi nastavili!',
                passCheck: (value) => value == this.registration.password || 'Passwordi razliciti'
            },
            phoneCheck: [
                (v) => {
                    if (!v) return 'Required.'

                    if (this.registration.country && v) {
                        if (!this.checkPhone(v, this.registration.country)) return 'Not a valid phone format'
                    }

                    return true
                }
            ]
        }
    },
    created() {},
    computed: {
        registrationStatus() {
            return this.$store.state.login.registrationOk
        },
        showError() {
            return this.$store.state.errorDialog
        },
        countries() {
            return this.$store.state.login.countries
        }
    },
    destroyed() {
        this.$store.commit('login/setRegistrationStatus', false)
        this.registration = {}
    },
    methods: {
        validateForm() {
            if (this.$refs.form.validate()) {
                if (!this.captchaToken) {
                    this.showCaptchaError = true
                } else {
                    if (!this.checkbox) {
                        this.$store.dispatch('')
                    }
                    this.showCaptchaError = false
                    this.registration.messages = this.checkbox1 ? '1' : '0'
                    this.$store.dispatch('login/register', this.registration)
                    return true
                }
            }
        },
        onVerify(value) {
            this.captchaToken = value
        },
        onExpired() {
            this.captchaToken = null
        },
        updatePhone() {
            if (country_phone_routes[countryISOMapping[this.registration.country]] != undefined) {
                this.registration.phone = country_phone_routes[countryISOMapping[this.registration.country]]
            }
        },
        checkPhone(phone, code) {
            try {
                const number = phoneUtil.parseAndKeepRawInput(phone.toString(), countryISOMapping[code])
                return phoneUtil.isValidNumber(number)
            } catch (error) {
                return
            }
        },
        closeError() {
            this.$store.commit('closeError')
        }
    }
}
</script>

<style scoped>
.register-card {
    padding: 50px;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.register-card .col-md-6 {
    padding-top: 5px;
    padding-bottom: 0px;
}

.logo h2 {
    color: #4c4c4c;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
}

.button-row {
    align-items: center;
    color: #4c4c4c;
}

.outer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.captcha-error {
    color: #ff5252;
    margin-top: 5px;
}

.buttons .v-input--selection-controls {
    margin-top: 0px;
}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #2f8acb;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #2f8acb;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
    position: relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #2f8acb;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #2f8acb;
    }
}

.animation {
    margin-bottom: 50px;
}

.acceptTerms {
    display: flex;
    align-items: baseline;
}

@media screen and (max-width: 475px) {
    .register-card {
        padding: 0px;
        box-shadow: none;
    }

    .acceptTerms {
        flex-direction: column;
    }

    .acceptTerms a {
        padding-left: 30px;
    }
}
</style>
