var country_phone_routes = new Array();

country_phone_routes['US'] = 1;
country_phone_routes['RU'] = 7;
country_phone_routes['EG'] = 20;
country_phone_routes['ZA'] = 27;
country_phone_routes['GR'] = 30;
country_phone_routes['NL'] = 31;
country_phone_routes['BE'] = 32;
country_phone_routes['FR'] = 33;
country_phone_routes['ES'] = 34;
country_phone_routes['HU'] = 36;
country_phone_routes['IT'] = 39;
country_phone_routes['RO'] = 40;
country_phone_routes['CH'] = 41;
country_phone_routes['AT'] = 43;
country_phone_routes['GB'] = 44;
country_phone_routes['DK'] = 45;
country_phone_routes['SE'] = 46;
country_phone_routes['NO'] = 47;
country_phone_routes['PL'] = 48;
country_phone_routes['DE'] = 49;
country_phone_routes['PE'] = 51;
country_phone_routes['MX'] = 52;
country_phone_routes['CU'] = 53;
country_phone_routes['AR'] = 54;
country_phone_routes['BR'] = 55;
country_phone_routes['CL'] = 56;
country_phone_routes['CO'] = 57;
country_phone_routes['VE'] = 58;
country_phone_routes['MY'] = 60;
country_phone_routes['AU'] = 61;
country_phone_routes['ID'] = 62;
country_phone_routes['PH'] = 63;
country_phone_routes['NZ'] = 64;
country_phone_routes['SG'] = 65;
country_phone_routes['TH'] = 66;
country_phone_routes['JP'] = 81;
country_phone_routes['KR'] = 82;
country_phone_routes['VN'] = 84;
country_phone_routes['CN'] = 86;
country_phone_routes['TR'] = 90;
country_phone_routes['IN'] = 91;
country_phone_routes['PK'] = 92;
country_phone_routes['AF'] = 93;
country_phone_routes['LK'] = 94;
country_phone_routes['MM'] = 95;
country_phone_routes['IR'] = 98;
country_phone_routes['MA'] = 212;
country_phone_routes['DZ'] = 213;
country_phone_routes['TN'] = 216;
country_phone_routes['LY'] = 218;
country_phone_routes['GM'] = 220;
country_phone_routes['SN'] = 221;
country_phone_routes['MR'] = 222;
country_phone_routes['ML'] = 223;
country_phone_routes['GN'] = 224;
country_phone_routes['CI'] = 225;
country_phone_routes['BF'] = 226;
country_phone_routes['NE'] = 227;
country_phone_routes['TG'] = 228;
country_phone_routes['BJ'] = 229;
country_phone_routes['MU'] = 230;
country_phone_routes['LR'] = 231;
country_phone_routes['SL'] = 232;
country_phone_routes['GH'] = 233;
country_phone_routes['NG'] = 234;
country_phone_routes['TD'] = 235;
country_phone_routes['CF'] = 236;
country_phone_routes['CM'] = 237;
country_phone_routes['CV'] = 238;
country_phone_routes['ST'] = 239;
country_phone_routes['GQ'] = 240;
country_phone_routes['GA'] = 241;
country_phone_routes['CG'] = 242;
country_phone_routes['CD'] = 243;
country_phone_routes['AO'] = 244;
country_phone_routes['GW'] = 245;
country_phone_routes['DG'] = 246;
country_phone_routes['SH'] = 247;
country_phone_routes['SC'] = 248;
country_phone_routes['SD'] = 249;
country_phone_routes['RW'] = 250;
country_phone_routes['ET'] = 251;
country_phone_routes['SO'] = 252;
country_phone_routes['DJ'] = 253;
country_phone_routes['KE'] = 254;
country_phone_routes['TZ'] = 255;
country_phone_routes['UG'] = 256;
country_phone_routes['BI'] = 257;
country_phone_routes['MZ'] = 258;
country_phone_routes['ZM'] = 260;
country_phone_routes['MG'] = 261;
country_phone_routes['YT'] = 262;
country_phone_routes['ZW'] = 263;
country_phone_routes['NA'] = 264;
country_phone_routes['MW'] = 265;
country_phone_routes['LS'] = 266;
country_phone_routes['BW'] = 267;
country_phone_routes['SZ'] = 268;
country_phone_routes['KM'] = 269;
country_phone_routes['SH'] = 290;
country_phone_routes['ER'] = 291;
country_phone_routes['AW'] = 297;
country_phone_routes['FO'] = 298;
country_phone_routes['GL'] = 299;
country_phone_routes['GI'] = 350;
country_phone_routes['PT'] = 351;
country_phone_routes['LU'] = 352;
country_phone_routes['IE'] = 353;
country_phone_routes['IS'] = 354;
country_phone_routes['AL'] = 355;
country_phone_routes['MT'] = 356;
country_phone_routes['CY'] = 357;
country_phone_routes['FI'] = 358;
country_phone_routes['BG'] = 359;
country_phone_routes['LT'] = 370;
country_phone_routes['LV'] = 371;
country_phone_routes['EE'] = 372;
country_phone_routes['MD'] = 373;
country_phone_routes['AM'] = 374;
country_phone_routes['BY'] = 375;
country_phone_routes['AD'] = 376;
country_phone_routes['MC'] = 377;
country_phone_routes['SM'] = 378;
country_phone_routes['VA'] = 379;
country_phone_routes['UA'] = 380;
country_phone_routes['RS'] = 381;
country_phone_routes['ME'] = 382;
country_phone_routes['HR'] = 385;
country_phone_routes['SI'] = 386;
country_phone_routes['BA'] = 387;
country_phone_routes['MK'] = 389;
country_phone_routes['CZ'] = 420;
country_phone_routes['SK'] = 421;
country_phone_routes['LI'] = 423;
country_phone_routes['FK'] = 500;
country_phone_routes['BZ'] = 501;
country_phone_routes['GT'] = 502;
country_phone_routes['SV'] = 503;
country_phone_routes['HN'] = 504;
country_phone_routes['NI'] = 505;
country_phone_routes['CR'] = 506;
country_phone_routes['PA'] = 507;
country_phone_routes['PM'] = 508;
country_phone_routes['HT'] = 509;
country_phone_routes['BL'] = 590;
country_phone_routes['BO'] = 591;
country_phone_routes['GY'] = 592;
country_phone_routes['EC'] = 593;
country_phone_routes['GF'] = 594;
country_phone_routes['PY'] = 595;
country_phone_routes['MQ'] = 596;
country_phone_routes['SR'] = 597;
country_phone_routes['UY'] = 598;
country_phone_routes['AN'] = 599;
country_phone_routes['TL'] = 670;
country_phone_routes['AQ'] = 672;
country_phone_routes['BN'] = 673;
country_phone_routes['NR'] = 674;
country_phone_routes['PG'] = 675;
country_phone_routes['TO'] = 676;
country_phone_routes['SB'] = 677;
country_phone_routes['VU'] = 678;
country_phone_routes['FJ'] = 679;
country_phone_routes['PW'] = 680;
country_phone_routes['WF'] = 681;
country_phone_routes['CK'] = 682;
country_phone_routes['NU'] = 683;
country_phone_routes['WS'] = 685;
country_phone_routes['KI'] = 686;
country_phone_routes['NC'] = 687;
country_phone_routes['TV'] = 688;
country_phone_routes['PF'] = 689;
country_phone_routes['TK'] = 690;
country_phone_routes['FM'] = 691;
country_phone_routes['MH'] = 692;
country_phone_routes['KP'] = 850;
country_phone_routes['HK'] = 852;
country_phone_routes['MO'] = 853;
country_phone_routes['KH'] = 855;
country_phone_routes['LA'] = 856;
country_phone_routes['BD'] = 880;
country_phone_routes['TW'] = 886;
country_phone_routes['MV'] = 960;
country_phone_routes['LB'] = 961;
country_phone_routes['JO'] = 962;
country_phone_routes['SY'] = 963;
country_phone_routes['IQ'] = 964;
country_phone_routes['KW'] = 965;
country_phone_routes['SA'] = 966;
country_phone_routes['YE'] = 967;
country_phone_routes['OM'] = 968;
country_phone_routes['PS'] = 970;
country_phone_routes['AE'] = 971;
country_phone_routes['IL'] = 972;
country_phone_routes['BH'] = 973;
country_phone_routes['QA'] = 974;
country_phone_routes['BT'] = 975;
country_phone_routes['MN'] = 976;
country_phone_routes['NP'] = 977;
country_phone_routes['TJ'] = 992;
country_phone_routes['TM'] = 993;
country_phone_routes['AZ'] = 994;
country_phone_routes['GE'] = 995;
country_phone_routes['KG'] = 996;
country_phone_routes['UZ'] = 998;
country_phone_routes['BS'] = 1242;
country_phone_routes['BB'] = 1246;
country_phone_routes['AI'] = 1264;
country_phone_routes['AG'] = 1268;
country_phone_routes['VG'] = 1284;
country_phone_routes['VI'] = 1340;
country_phone_routes['KY'] = 1345;
country_phone_routes['BM'] = 1441;
country_phone_routes['GD'] = 1473;
country_phone_routes['TC'] = 1649;
country_phone_routes['MS'] = 1664;
country_phone_routes['MP'] = 1670;
country_phone_routes['GU'] = 1671;
country_phone_routes['AS'] = 1684;
country_phone_routes['LC'] = 1758;
country_phone_routes['DM'] = 1767;
country_phone_routes['VC'] = 1784;
country_phone_routes['PR'] = 1787;
country_phone_routes['UM'] = 1808;
country_phone_routes['DO'] = 1809;
country_phone_routes['DO'] = 1829;
country_phone_routes['TT'] = 1868;
country_phone_routes['KN'] = 1869;
country_phone_routes['JM'] = 1876;
country_phone_routes['PR'] = 1939;
country_phone_routes['SS'] = 211;
country_phone_routes['DO'] = 1849;

export default country_phone_routes